.award-print-container {
  page-break-inside: avoid;
  width: 1674px;
  transform: rotate(90deg) scale(0.96, 0.96);
  translate: -156px 350px;
}

.award-print-container-sp {
  page-break-inside: avoid;
  width: 1660px;
  transform: rotate(90deg) scale(0.8, 0.8);
  translate: -196px 250px;
}

.award-student-info {
  font-size: 46px;
  padding-left: 228px;
}

.award-row-3-4 {
  font-size: 28px;
  padding-left: 100px;
  padding-top: 382px;
}

.award-row-4 {
  padding-top: 68px;
}

.award-point {
  width: 152px;
}
