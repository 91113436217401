.students-table-container {
  width: 900px;
}

.students-codes-excel-btn {
  width: 88px !important;
}

.students-codes-excel-btn-eng {
  width: 124px !important;
}
