@tailwind base;
@tailwind components;
@tailwind utilities;

.theme-nav-title {
  @apply text-2xl font-bold;
}

.table-border {
  @apply border text-center text-lg p-2 print:text-xs print:border-black print:px-1 print:py-0;
}

.table-border-yellow {
  @apply table-border border-yellow-400 print:border-black;
}

.table-border-total {
  @apply table-border border-purple-400 print:border-black;
}

.table-border-male {
  @apply table-border border-blue-400 print:border-black;
}

.table-border-female {
  @apply table-border border-pink-400 print:border-black;
}

.result-table-border {
  @apply border p-2;
}

@media screen {
  .print-component {
    display: none;
  }
}

.text-delete {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.student-page .ant-modal-body {
  padding: 18px;
}

.table-static-home {
  color: #333333;
}

.table-static-home thead th {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
}

.table-static-home tbody {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
  text-align: center;
}

.table-static-home tbody .table-header td {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  border-bottom: 1px solid #c4c4c4;
}

.school-grade-name {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  padding-top: 10px;
}

.table-static-home tbody .table-header td span {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  width: 70px;
  display: flex;
  justify-content: center;
  height: 24px;
  background: #e1e1e1;
  border-radius: 4px;
  align-items: center;
  margin: 10px 8px 6px;
}

.table-static-home tbody .table-header td span.color-success {
  background: rgba(0, 148, 77, 0.1);
}

.table-static-home tbody .table-header td span.color-red {
  background: rgba(184, 3, 3, 0.1);
}

.table-static-home tbody .border-right {
  border-right: 1px solid #c4c4c4;
}

.color-success {
  color: #00944d;
}

.color-red {
  color: #b80303;
}

.color-red-f {
  color: #b80303 !important;
}

.table-body td {
  padding: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.home-menu {
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-bottom: 16px;
}

.home-menu .icon-number {
  background: #f0f3f6;
  font-family: 'Hiragino Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #036eb8;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 24px 0 24px 24px;
}

.home-menu .image-menu {
  margin-right: 13px;
  margin-left: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-menu-content {
  color: #000000;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.home-menu-content .home-menu-title {
  margin-top: 8px;
  margin-bottom: 2px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.home-menu-link {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 10px;
  margin: 28px 13px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  height: 21px;
}
.home-menu-link span {
  margin-right: 4px;
}

.form-date-of-birth .ant-form-item {
  margin-top: 8px;
}

.list-video .ant-tag {
  height: 24px;
  width: 64px;
  border-radius: 2px;
  margin-top: 4px;
  border: none;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
}

.form-print .page-break-auto .recharts-wrapper {
  padding-top: 10px;
}

.text-underline {
  text-decoration: underline;
}

.color-blue {
  color: #036eb8;
}

.color-blue-f {
  color: #036eb8 !important;
}

.border-1px {
  border-width: 1px;
}

.h-a4 {
  height: 29.7cm;
}

.w-400px {
  width: 400px;
}

.w-624px {
  width: 624px;
}

.min-w-680px {
  min-width: 680px;
}

.w-800px {
  width: 800px;
}

.h-240px {
  height: 240px;
}

.p-44px-20px {
  padding: 44px 20px;
}

.border-yellow-400-f {
  border-color: #ffc107 !important;
}

.bg-yellow-fff5db-f {
  background-color: #fff5db !important;
}

.pointer-none-f {
  pointer-events: none !important;
}
