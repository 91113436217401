.dropdown-container {
  position: relative;
}

.dropdown-button {
  padding-bottom: 14px;
  padding-top: 14px;
  background-color: #036eb8;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  background-color: rgb(9, 0, 142);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  padding: 0;
  margin: 0;
  width: 200px;
  z-index: 1050;
}

.dropdown-item {
  padding: 12px 16px;
  cursor: pointer;
  background-color: #036eb8;
  border-bottom-width: 1px;
  border-bottom-color: #478cbc;
}

.dropdown-item:hover {
  background-color: #007fd9;
}

.dropdown-item:focus {
  color: #ffc833 !important;
  border-bottom-color: #ffc833 !important;
}

.dropdown-item-selected {
  color: #ffc833 !important;
  border-bottom-color: #ffc833 !important;
}

.dropdown-item-close {
  padding: 12px 16px;
  color: #036eb8;
}
