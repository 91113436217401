.summary-title {
  margin-top: 52px;
}

.summary-title-en {
  margin-top: 44px;
}

.summary-point {
  height: 54px;
}
