.test-filter-group-btn-ja {
  min-width: 352px;
}

.test-filter-group-btn-en {
  min-width: 380px;
}

.test-header {
  background: #e5dab8;
  padding: 5px 10px;
  border: 1px solid black;
}

.test-header-title {
  color: red;
}

.test-register-btn {
  padding-top: 14px;
}

.test-register-btn-ja {
  font-size: 30px !important;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 60px;
}

.test-register-btn-en {
  font-size: 24px !important;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 52px;
}

.form-edit-table-test .text-title {
  position: relative;
  top: 14px;
  width: max-content;
}

.form-edit-table-test .text-title-en {
  top: 8px !important;
}

.form-edit-table-test .left-24 {
  left: unset;
  right: -5px;
}

.form-edit-table-test .right-unit {
  left: unset;
  right: -5px;
}

.form-edit-table-test .ant-form-item-explain-error {
  white-space: pre-line;
}

.test-result-details-btn {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  height: 24px !important;
}
