.min-h-909px {
    min-height: 909px;
}

/* Print-friendly styles */
.print-page-for-1 {
    height: 100%;
    min-height: 1000px;
    width: 100%;
    page-break-after: always;
    page-break-inside: avoid;
}

.h-a4 {
    min-height: 1000px;
    position: relative;
}

@media print {
    .print-container {
        width: 100%;
        height: 100%;
    }
    
    .print-page-for-1 {
        height: 100%;
        min-height: 900px;
        width: 100%;
        page-break-after: always;
        page-break-inside: avoid;
    }
    
    .h-a4 {
        height: 100vh;
        width: 100%;
        page-break-after: always;
        page-break-inside: avoid;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    
    /* Safari and Chrome compatible styles for 9-QR layout */
    .page-break-auto {
        page-break-inside: avoid;
        page-break-after: always;
        box-sizing: border-box;
    }
    
    .page-break-auto .grid-cols-3 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0.5rem !important; /* Reduce gap for printing */
        transform: scale(0.95);
        transform-origin: top center;
        box-sizing: border-box;
    }
    
    /* Safari-specific fixes */
    @media not all and (min-resolution:.001dpcm) { 
        @supports (-webkit-appearance:none) and (stroke-color:transparent) {
            .page-break-auto .grid-cols-3 {
                transform: scale(0.92);
                margin-top: -0.5rem;
            }
            
            .h-a4 {
                max-height: 100vh;
            }
        }
    }
    
    /* Footer text positioning */
    .print-footer {
        position: absolute;
        bottom: 1.5cm;
        left: 0;
        right: 0;
        text-align: center;
    }
    
    /* Ensure proper page size */
    @page {
        size: A4 portrait;
        margin: 0.5cm;
    }
}