.page-break-before {
  page-break-before: always;
}

.page-break-after {
  page-break-after: always;
}

.landscape-print-container {
  page-break-after: always;
  /* A4: 297 / 210 */
  /* width: 1273px; */
  height: 900px;
  /* transform: scale(1.291, 1.291); */
  transform: rotate(90deg) scale(1.291, 1.291);
  margin-top: 391px;
  margin-left: -52px;

}

.landscape-print-container-sp {
  page-break-after: always;
  /* A4: 297 / 210 */
  /* width: 1273px; */
  height: 900px;
  transform: rotate(90deg) scale(1.1, 1.1);
  margin-top: 210px;
  margin-left: -100px;
}

.test-result-detail-container {
  /* A4: 297 / 210 */
  width: 1273px;
}

.test-result-detail-container-sp {
  /* A4: 297 / 210 */
  width: 1200px;
}

.result-title-container {
  background-color: #1d7bbe;
  color: #ffffff;
  padding: 1%;
  font-size: 188%;
  font-weight: bold;
  width: 28%;
}

.result-info-container {
  width: 72%;
  color: #000000;
}

.background-input {
  background-color: #deeefa;
}

.round-border {
  border-radius: 12px;
}

.year-school-name-logo-container {
  padding-left: 2%;
  padding-bottom: 1%;
}

.year-container {
  width: 18%;
  margin-right: 1.4%;
}

.year-val {
  padding-left: 22%;
  padding-right: 8%;
}

.school-name-txt {
  padding-left: 1%;
  padding-right: 1%;
}

.school-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  margin-right: 1%;
}

.student-info-container {
  padding-left: 1%;
  padding-bottom: 0.7%;
  border-bottom: #1d7bbe solid 2px;
}

.student-info-val {
  width: 5%;
  margin-left: 4%;
}

.student-info-name-txt {
  margin-left: 5%;
  margin-right: 2%;
}

.bmi-container {
  padding-top: 6px;
  padding-bottom: 6px;
  justify-content: flex-end;
}

.bmi-txt {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 2%;
  padding-right: 6px;
}

.bmi-input {
  width: 10%;
}

.bmi-val {
  padding: 2px 6px;
}

.bmi-unit {
  font-size: small;
  top: 6px;
  right: 6px;
}

.summary-val-container {
  background-color: #ffdf6e;
}

.summary-item {
  width: calc(50% - 12px);
}

.questions-table-new-page-container {
  margin-top: -454px;
  margin-left: 630px;
  /* A4: 297 / 210 */
  /* 1273px - p-2 (8px 8px) = 1257 */
  width: 1257px;
}
